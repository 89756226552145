import { baseURL, cache } from './consts';
import { addDropdownItem, addMultiSelectDropdownItem } from './dropdowns';

// Obtains all cars from the API, populates the cars dropdown with all the
// cars obtained, and creates an ID to object mapping in the cache
export const loadCars = async () => {
  console.debug('load cars');

  return axios.get(`${baseURL}/cars`)
    .then(response => {
      cache.cars = {};

      response.data.forEach(item => {
        if (!cache.cars.hasOwnProperty(item.race_class)) {
          cache.cars[item.race_class] = [];
        }

        cache.cars[item.race_class][item.id] = item
      })

      const $dropdown = $('.table-filters .cars-dropdown .dropdown-menu');
      populateCarsDropdown($dropdown, true);
      console.debug('processed cars');
    })
    .catch(error => console.error('load cars:', error));
}

export const populateCarsDropdown = ($dropdown, addAll) => {
  $dropdown.empty();

  const $dropdownWrapper = $dropdown.closest('.select-dropdown');
  const isSingleSelect = $dropdownWrapper.hasClass('single-select-dropdown');
  const isMultiSelect = $dropdownWrapper.hasClass('multi-select-dropdown');

  if (addAll) {
    if (isSingleSelect) {
      addDropdownItem($dropdown, 'all', 'All');
    }

    if (isMultiSelect) {
      addMultiSelectDropdownItem($dropdown, 'allCars', 'All');
    }
  }

  Object.keys(cache.cars).forEach(raceClass => {
    $dropdown.append(
      $('<li></li>').append(
        $('<p></p>').addClass('dropdown-text-divider').text(raceClass)
      )
    );

    Object.values(cache.cars[raceClass]).forEach(item => {
      const itemId = item.id;
      const itemName = carName(item);
      const itemImage = `/assets/images/manufacturers/${item.manufacturer.toLowerCase().replace(/ /g, '_')}.png`;
      const itemManufacturer = item.manufacturer;

      if (isSingleSelect) {
        addDropdownItem($dropdown, itemId, itemName, itemImage, itemManufacturer);
      }

      if (isMultiSelect) {
        addMultiSelectDropdownItem($dropdown, itemId, itemName, itemImage, itemManufacturer);
      }
    });
  });
}

export const getCarByID = id => {
  return Object.values(cache.cars).find(carClass => carClass[id])?.[id];
}

export const carName = car => {
  return `${car.manufacturer} ${car.model}` + (car.spec != '' ? ` (${car.spec})` : '')
}
