export const removeExtension = filename => {
  const lastDotIndex = filename.lastIndexOf('.svm');
  if (lastDotIndex === -1) return filename;
  return filename.substring(0, lastDotIndex);
}

export const isValidURL = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export const isValidLaptime = laptime => {
  const laptimePattern = new RegExp('^[0-9]{1,2}:[0-9]{2}:[0-9]{3}');
  return !!laptimePattern.test(laptime);
}

export const uppercaseFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getURLParam = name => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

export const moveRight = ($element, duration = 500) => {
  const start = Date.now();
  const interval = 20;
  const distance = window.innerWidth;

  const animate = () => {
    const timePassed = Date.now() - start;
    const progress = timePassed / duration;

    if (progress < 1) {
      $element.css('transform', `translateX(${progress * distance}px)`);
      setTimeout(animate, interval);
    } else {
      $element.css('transform', `translateX(${distance}px)`);
      $element.remove()
    }
  }

  animate();
}

export const moveLeft = ($element, duration = 500) => {
  const start = Date.now();
  const interval = 20;
  const distance = window.innerWidth;

  $element.css('transform', `translateX(${distance}px)`);

  const animate = () => {
    const timePassed = Date.now() - start;
    const progress = 1 - (timePassed / duration);

    if (progress > 0) {
      $element.css('transform', `translateX(${progress * distance}px)`);
      setTimeout(animate, interval);
    } else {
      $element.css('transform', `translateX(0px)`);
    }
  }

  animate();
}
