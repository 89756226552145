import { moveRight, moveLeft } from './helpers';

export const showError = (error, details) => {
  const container = $('.notification-container');
  const el = $('<div></div>').
    addClass('.notification alert alert-danger').
    text(error);

  if (typeof details !== 'undefined') {
    el.append($('<div></div>').addClass('notification-details').text(details))
  }

  container.append(el);

  moveLeft(el);

  setTimeout(() => { moveRight(el) }, 10000);
}

$('.notification-container').on('click', '.notification', event => {
  const el = $(event.currentTarget);
  console.log('notif clicked')
  moveRight(el);
});
