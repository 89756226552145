import { cache, setupFilter } from './consts';
import { dataAPI } from './api';
import { removeExtension, isValidURL, uppercaseFirst } from './helpers';
import { getCarByID, carName } from './cars';
import { getLoggedInUser } from './login';

export const initSetupTable = async () => {
  console.debug('load setups');

  await loadSetups({});

  const loadWithFilters = () => {
    const populateFilter = (filterArray, dropdownClass) => {
      const checkboxParentSelector = `.table-filters .${dropdownClass}.multi-select-dropdown ul `;
      $(`${checkboxParentSelector} input[type=checkbox]:checked:not(.all-checkbox)`).each(function () {
        const id = $(this).parent('li').attr('data-item_id');
        filterArray.push(id);
      });
    };

    setupFilter.cars.length = 0;
    setupFilter.tracks.length = 0;
    setupFilter.types.length = 0;
    setupFilter.conditions.length = 0;

    populateFilter(setupFilter.cars, 'cars-dropdown');
    populateFilter(setupFilter.tracks, 'tracks-dropdown');
    populateFilter(setupFilter.types, 'setup-type-dropdown');
    populateFilter(setupFilter.conditions, 'condition-dropdown');

    loadSetups();
  };

  console.log('attach events')
  document.getElementById('setupCarsFilterButton').addEventListener('hidden.bs.dropdown', loadWithFilters);
  document.getElementById('setupTracksFilterButton').addEventListener('hidden.bs.dropdown', loadWithFilters);
  document.getElementById('setupTypeFilterButton').addEventListener('hidden.bs.dropdown', loadWithFilters);
  document.getElementById('setupConditionFilterButton').addEventListener('hidden.bs.dropdown', loadWithFilters);
}

export const loadSetups = async () => {
  $('.loading-overlay').show();

  let qs = '?';
  if (setupFilter.cars.length > 0) {
    qs += 'filter[car_id]=' + setupFilter.cars.join(',') + '&';
  }
  if (setupFilter.tracks.length > 0) {
    qs += 'filter[track_id]=' + setupFilter.tracks.join(',') + '&';
  }
  if (setupFilter.types.length > 0) {
    qs += 'filter[type]=' + setupFilter.types.join(',') + '&';
  }
  if (setupFilter.conditions.length > 0) {
    qs += 'filter[condition]=' + setupFilter.conditions.join(',') + '&';
  }

  dataAPI.get(`/setups${qs}`)
    .then(response => {
      console.debug('track data fetched');
      const $tbody = $('#setupsTable tbody');

      $tbody.empty();

      response.data.forEach(item => {
        addSetupRow($tbody,
          item.id,
          item.track_id,
          item.car_id,
          item.file_name,
          item.laptime,
          item.type,
          item.condition,
          item.rating,
          item.display_name,
          item.downloads,
          item.video_link,
          item.user_vote);
      });

      $('.loading-overlay').hide();

      console.debug('processed setups')
    })
    .catch(error => console.error('load setups:', error));
}

// Adds a single table row item
export const addSetupRow = ($tbody, id, track_id, car_id, name, laptime, type, condition, rating, user, downloads, video, user_vote) => {
  let car = getCarByID(car_id);

  const $trackName = $('<td></td>').attr('scope', 'row').text(cache.tracks[track_id].track_name);
  const $carName = $('<td></td>').text(carName(car));
  const $name = $('<td></td>').text(removeExtension(name));
  const $laptime = $('<td></td>').text(laptime)
    .addClass('d-none d-md-table-cell');
  const $type = $('<td></td>').text(type.capitalize())
    .addClass('d-none d-xl-table-cell');
  const $condition = $('<td></td>').text(condition.capitalize())
    .addClass('d-none d-xl-table-cell');

  let upvote = user_vote === true ? 'solid' : 'regular';
  let downvote = user_vote === false ? 'solid' : 'regular';
  const $rating = $('<td></td>')
    .append($('<span></span>').text(rating/100).addClass('setup-rating'))
    .addClass('d-none d-xl-table-cell')

  if (getLoggedInUser() !== false) {
    $rating
      .append($('<span></span>').addClass(`fa-${upvote} fa-thumbs-up icon-link link-primary upvote setup-vote`))
      .append($('<span></span>').addClass(`fa-${downvote} fa-thumbs-down icon-link link-primary downvote setup-vote`));
  }

  const $user = $('<td></td>').text(user)
    .addClass('d-none d-xl-table-cell');
  const $downloads = $('<td></td>').addClass('download-counter').text(downloads);
  const $buttons = $('<td></td>')
    .addClass('setup-row-controls')
    .append(
      $('<span></span>')
      .addClass('fa-solid fa-download icon-link link-primary download-setup')
      .attr('data-setup_id', id)
      .attr('data-setup_name', name)
    );

  if (typeof(video) !== undefined && video !== null && video !== '' && isValidURL(video)) {
    $buttons.append(
      $('<a></a>')
      .addClass('fa-brands fa-youtube icon-link link-primary setup-video-link')
      .attr('href', video)
      .attr('target', '_blank')
    );
  }

  $tbody.append($('<tr></tr>')
    .addClass('setup-row')
    .attr('data-setup_id', id)
    .attr('data-setup_name', name)
    .append(
      //$name,
      $trackName,
      $carName,
      $laptime,
      $type,
      $condition,
      $rating,
      $user,
      $downloads,
      $buttons
    ));
}

$(document).on('click', '.setup-video-link', event => {
  event.stopImmediatePropagation();
});

$(document).on('click', '.download-setup', event => {
  event.stopImmediatePropagation();

  let el = $(event.currentTarget);
  let id = el.attr('data-setup_id');
  let name = el.attr('data-setup_name');

  dataAPI.get(`/setups/download/${id}`)
    .then(response => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const downloadUrl = window.URL.createObjectURL(blob);

      const $a = $('<a></a>');
      $a.attr('href', downloadUrl);
      $a.attr('download', name);
      $('body').append($a);
      $a[0].click();
      $a.remove();

      let counter = $(`.setup-row[data-setup_id='${id}'] .download-counter`);
      counter.text(parseInt(counter.text()) + 1);
    })
    .catch(error => {
      console.error('Download failed', error);
    });
});

$(document).on('click', '.setup-vote', event => {
  event.stopImmediatePropagation();

  const el = $(event.currentTarget);
  const row = el.closest('.setup-row');
  const id = row.attr('data-setup_id');
  let vote = null;

  if (el.hasClass('upvote')) {
    vote = 'upvote';
  } else if (el.hasClass('downvote')) {
    vote = 'downvote';
  } else {
    console.error('unable to determine vote type');
  }

  dataAPI.post(`/setups/rate/${vote}/${id}`)
    .then(response => {
      row.find('.setup-vote').removeClass('fa-solid').addClass('fa-regular');
      el.removeClass('fa-regular').addClass('fa-solid');
      row.find('.setup-rating').text(response.data.rating/100);
    })
    .catch(error => {
      console.error('record vote on a setup:', error);
    })
})

$(document).on('click', '.setup-row', event => {
  let el = $(event.currentTarget);
  let id = el.attr('data-setup_id');

  window.history.pushState(null, document.title, `/setup/${id}`);

  showSetupModal(id);
});

export const showSetupModal = (id) => {
  dataAPI.get(`/setups/${id}`)
    .then(response => {
      const car = getCarByID(response.data.car_id);

      let descriptionEl = $('#setupDetailsModal .setupDescriptionInput');
      let videoEl = $('#setupDetailsModal .setupVideoInput');
      let carEl = $('#setupDetailsModal .setupCarInput');
      let trackEl = $('#setupDetailsModal .setupTrackInput');
      let laptimeEl = $('#setupDetailsModal .setupLaptimeInput');
      let typeEl = $('#setupDetailsModal .setupTypeInput');
      let conditionEl = $('#setupDetailsModal .setupConditionInput');

      const user = getLoggedInUser();
      if (user !== false && user.id === response.data.user_id) {
        const modal = new bootstrap.Modal(document.getElementById('editModal'));
        modal.show();

        descriptionEl = $('#editModal .setupDescriptionInput');
        videoEl = $('#editModal .setupVideoInput');
        carEl = $('#editModal .setupCarInput');
        trackEl = $('#editModal .setupTrackInput');
        laptimeEl = $('#editModal .setupLaptimeInput');
        typeEl = $('#editModal .setupTypeInput');
        conditionEl = $('#editModal .setupConditionInput');

        $(`#editModal .setupID`).attr('data-setup_id', id)
      } else {
        const modal = new bootstrap.Modal(document.getElementById('setupDetailsModal'));
        modal.show();
      }

      $('#setupDetailsDownload')
        .attr('data-setup_id', id)
        .attr('data-setup_name', response.data.file_name);
      descriptionEl.val(response.data.description);

      if (response.data.video_link) {
        if (videoEl.is('input')) {
          videoEl.val(response.data.video_link);
        } else if (videoEl.is('a')) {
          videoEl.text(response.data.video_link)
            .attr('href', response.data.video_link);
        }
      }

      carEl.text(carName(car));
      carEl.siblings().find(`.dropdown-item[data-item_id='${response.data.car_id}']`).addClass('selected');

      trackEl.text(cache.tracks[response.data.track_id].track_name);
      trackEl.siblings().find(`.dropdown-item[data-item_id='${response.data.track_id}']`).addClass('selected');

      laptimeEl.val(response.data.laptime);

      typeEl.text(uppercaseFirst(response.data.type));
      typeEl.siblings().find(`.dropdown-item[data-item_id='${response.data.type}']`).addClass('selected');

      conditionEl.text(uppercaseFirst(response.data.condition));
      conditionEl.siblings().find(`.dropdown-item[data-item_id='${response.data.condition}']`).addClass('selected');
    })
    .catch(error => {
      console.error('populate setup details modal:', error);
    })
};
