import { initSetupTable, showSetupModal } from './setup_table';
import { loadTracks, populateTracksDropdown } from './tracks';
import { loadCars, populateCarsDropdown } from './cars';
import './setup';

import uploadSetupModal from './../../views/partials/upload_setup.html';
import setupDetailsModal from './../../views/partials/setup_details.html';

$(() => {
  init();
});

const init = async () => {
  try {
    loadModals();

    await Promise.all([loadTracks(), loadCars()]);
    await initSetupTable();

    const path = window.location.pathname;
    route(path);

    console.debug('index site initialized');
  } catch (error) {
    console.error('initialize index data:', error);
  }
}

const route = (path) => {
  const setup = path.match(/^\/setup\/([a-f0-9-]{36})$/);

  if (setup) {
    const id = setup[1];
    showSetupModal(id);
  }
}

const insertModal = ({
  modalHTML,
  id,
  preInit = null,
  postAppend = null,
  onOpen = null,
  onClose = null,
}) => {
  if (preInit !== null) {
    modalHTML = preInit(modalHTML);
  }

  $('body').append(modalHTML);
  if (postAppend !== null) {
    postAppend();
  }

  const originalContent = $(`#${id}`).html();

  document.getElementById(id).addEventListener('show.bs.modal', event => {
    if (onOpen !== null) {
      onOpen(event);
    }
  });

  document.getElementById(id).addEventListener('hidden.bs.modal', event => {
    if (onClose !== null) {
      onClose(event);
    }

    $(`#${id}`).html(originalContent);
  });
}

const loadModals = () => {
  // Create setup modal
  insertModal({
    modalHTML: uploadSetupModal,
    id: 'uploadModal',
    onOpen: event => {
      populateTracksDropdown($('#uploadModal .tracks-dropdown .dropdown-menu'), false);
      populateCarsDropdown($('#uploadModal .cars-dropdown .dropdown-menu'), false);
    },
  });

  // Edit setup modal
  insertModal({
    modalHTML: uploadSetupModal,
    id: 'editModal',
    preInit: html => {
      return html.replaceAll('uploadModal', 'editModal').replaceAll('createSetup', 'editSetup');
    },
    postAppend: () => {
      $('#editModal .modal-title').text('Edit setup');
      $('#editModal .file-upload-disclaimer').show();
    },
    onOpen: event => {
      populateTracksDropdown($('#editModal .tracks-dropdown .dropdown-menu'), false);
      populateCarsDropdown($('#editModal .cars-dropdown .dropdown-menu'), false);
    },
    onClose: event => {
      window.history.pushState(null, document.title, `/`);
    },
  });

  // Show setup modal
  insertModal({
    modalHTML: setupDetailsModal,
    id: 'setupDetailsModal',
    onClose: event => {
      window.history.pushState(null, document.title, `/`);
    },
  });
}
