import { baseURL, cache } from './consts';

// Adds a single dropdown item
export const addDropdownItem = ($dropdown, id, text, image, alt) => {
  $dropdown.append(
    $('<li></li>').append(
      $('<a></a>').addClass('dropdown-item').attr('href', '#').attr('data-item_id', id).append(
        $('<img></img>')
        .addClass('dropdown-icon')
        .attr('src', image)
        .attr('alt', alt),
        $('<span></span>').addClass('dropdown-text').text(text)
      )
    )
  );
}

export const addMultiSelectDropdownItem = ($dropdown, id, text, image, alt) => {
  const $check = $('<input>').attr('type', 'checkbox').attr('id', id);

  if (text === 'All') {
    $check.attr('checked', true).addClass('all-checkbox');
  }

  $dropdown.append(
    $('<li></li>').addClass('dropdown-item').attr('data-item_id', id).append(
      $check,
      $('<label></label>').attr("for", id).append(
        $('<img></img>')
        .addClass('dropdown-icon')
        .attr('src', image)
        .attr('alt', alt),
        $('<span></span>').addClass('dropdown-text').text(text)
      )
    )
  );
}

$(document).on('click', '.single-select-dropdown .dropdown-item', event => {
  let item = $(event.currentTarget);

  item.closest('li').siblings().find('.dropdown-item').each((idx, el) => {
    $(el).removeClass('selected');
  })

  item.addClass('selected');

  let dropdownButton = item.closest('.single-select-dropdown').find('.form-select');
  let itemText = item.find('.dropdown-text');
  if (itemText.length > 0) {
    dropdownButton.text(itemText.text());
  } else {
    dropdownButton.text(item.text());
  }
});

$(document).on('click', '.multi-select-dropdown .dropdown-item', event => {
  const el = $(event.currentTarget);
  const checkbox = el.find('input[type="checkbox"]');

  if (checkbox.hasClass('all-checkbox')) {
    // uncheck all others
    el.parent('.dropdown-menu').find('.dropdown-item input[type="checkbox"]').prop('checked', false);
  } else {
    // uncheck all-checkbox
    el.parent('.dropdown-menu').find('.dropdown-item .all-checkbox').prop('checked', false);
  }

  checkbox.prop('checked', !checkbox.prop('checked'));

  // re-check all-checkbox if no checkboxes are checked
  let noChecked = true;
  el.parent('.dropdown-menu').find('.dropdown-item input[type="checkbox"]').each((idx, checkEl) => {
    if ($(checkEl).prop('checked') === true) {
      noChecked = false;
      return;
    }
  })
  if (noChecked === true) {
    // check all-checkbox
    el.parent('.dropdown-menu').find('.dropdown-item .all-checkbox').prop('checked', true);
  }
});
